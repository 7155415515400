import { Component, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHandHoldingDollar,
  faChurch,
} from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '@trinity-christian-church/shared';

@Component({
  selector: 'tcc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public faHoldingDollar = faHandHoldingDollar as IconProp;
  public faChurch = faChurch as IconProp;
  public year = '';
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.retrieve('/api/footer').subscribe((data) => {
      console.log('year', data);
      this.year = data.year;
    });
  }
}
