import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public shouldAnimate = false;
  public toastNotificaiton: BehaviorSubject<Toast> = new BehaviorSubject({
    type: 'error',
    title: 'Uh oh, something went wrong',
    subTitle: 'Please Enter Amount',
    icon: 'faExclamationTriangle',
  });

  constructor() {}

  public scrollToTop() {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  public elementInView(el: any, percentageScroll = 1) {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) /
        percentageScroll
    );
  }

  public elementOutofView(el: any) {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop > (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  public handleScrollAnimation(currentEl: any) {
    if (currentEl) {
      if (this.elementInView(currentEl, 1.25)) {
        console.log('element in view');
        this.shouldAnimate = true;
      }

      if (this.elementOutofView(currentEl)) {
        console.log('elemen not in view');
        this.shouldAnimate = false;
      }
    }

    return this.shouldAnimate;
  }
}

interface Toast {
  type: string;
  title: string;
  subTitle: string;
  icon: string;
  showToast?: boolean;
}
