<div class="footer-container">
  <div class="footer-middle">
    <div class="left-links">
      <a [routerLink]="['/']">
        <fa-icon [icon]="faChurch"></fa-icon>
        Home
      </a>

      <!-- <a [routerLink]="['/']">
        <i class="fas fa-user footer-icon"></i>
        Trinity Giving
      </a>
      <a [routerLink]="['/']">
        <i class="fa fa-line-chart footer-icon"></i>
        What we do
      </a> -->
    </div>
    <div class="right-links">
      <a href="https://www.facebook.com/TrinityChristianChurch48180">
        <i class="fab fa-facebook-square footer-icon"></i>
        Facebook
      </a>
      <!-- <a href="https://twitter.com/elynnjac">
        <i class="fab fa-twitter-square footer-icon"></i>
        Twitter
      </a>
      <a href="https://www.instagram.com/estherljackson/">
        <i class="fab fa-instagram-square footer-icon"></i>
        Instagram
      </a> -->
    </div>

    <div class="middle-links">
      <a href="#trinity-pay">
        <fa-icon [icon]="faHoldingDollar"></fa-icon>
        Trinity Giving
      </a>
      <!-- <a [routerLink]="['/']">
        <i class="fas fa-mobile footer-icon"></i>
        Download Our App
      </a>
      <a [routerLink]="['/']">
        <i class="fas fa-address-card footer-icon"></i>
        About
      </a>

      <a [routerLink]="['/']">
        <i class="fa fa-phone-square footer-icon"></i>
        Contact
      </a> -->
    </div>
  </div>
  <span class="footer-name">Trinity Christian Church &copy; {{ year }} </span>
  <span class="powered-by-name">Powered By: CMJ Software </span>
</div>
