<div (click)="handleClick()" class="toast">
  <canvas></canvas>
  <div id="toasted" class="toast_container">
    <span
      [ngClass]="{
        toast_container_error: toast?.type === 'error',
        toast_container_success: toast?.type === 'success'
      }"
    ></span>

    <div class="toast_container_msg">
      <span
        [ngClass]="{
          toast_container_icon_error: toast?.type === 'error',
          toast_container_icon_success: toast?.type === 'success'
        }"
      >
        <fa-icon [icon]="getIcon()"></fa-icon>
      </span>
      <h1>{{ toast?.title }}</h1>
      <h2
        [ngClass]="{
          toast_container_msg_sub_error: toast?.type === 'error',
          toast_container_msg_sub_success: toast?.type === 'success'
        }"
      >
        {{ toast?.subTitle }}
      </h2>
    </div>
    <button (click)="handleClick()" class="toast_container_btn">X</button>
  </div>
</div>
