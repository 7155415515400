import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {
    //
  }

  public retrieve(url: string): Observable<any> {
    console.log('making a request');

    return this.http
      .get(url)
      .pipe(catchError(async (err) => console.log('Error in api', err)));
  }

  public update() {
    console.log('update a request');
  }

  public remove() {
    console.log('remove a request');
  }

  private processRequest(request: Observable<void>) {
    return request.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError(async () => console.log('error out in apiservice'))
    );
  }
}
