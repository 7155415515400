import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tcc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public navLinks = [
    {
      img: 'https://tcc-sites.s3.us-east-2.amazonaws.com/imgs/home/about.jpeg',
      title: 'About',
    },
    {
      img: 'https://tcc-sites.s3.us-east-2.amazonaws.com/imgs/home/church.jpeg',
      title: 'Location',
    },
    {
      img: 'https://tcc-sites.s3.us-east-2.amazonaws.com/imgs/home/ministry.jpeg',
      title: 'Ministries',
    },
    {
      img: 'https://tcc-sites.s3.us-east-2.amazonaws.com/imgs/home/sermons.jpeg',
      title: 'Sermons',
    },
  ];
  constructor() {}

  ngOnInit(): void {
    this.createOnScroll();
  }

  createOnScroll() {
    if (window) {
      window.addEventListener('scroll', () => {
        const header = document.getElementById('tcc-header');
        if (window.scrollY || window.pageYOffset / 150) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          header?.classList.add('header_appear');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          header?.classList.remove('header_appear');
        }
      });
    }
  }

  handleClick() {
    console.log('clicked link');
  }
}
