import { Component, OnInit } from '@angular/core';
import { AppService } from '@trinity-christian-church/shared';

@Component({
  selector: 'tcc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tcc';
  public showToast = false;
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.toastNotificaiton.subscribe((msg) => {
      this.showToast = msg?.showToast || false;
    });
  }
}
