import { Component, OnInit } from '@angular/core';
import { Icon, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faThumbsUp,
  faExclamationTriangle,
  faHandHoldingHeart,
} from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../app/app.service';

@Component({
  selector: 'tcc-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  public faThumbsUp = faThumbsUp as IconProp;
  public faExclamationTriangle = faExclamationTriangle as IconProp;
  public faHandHoldingHeart = faHandHoldingHeart as IconProp;
  public toast: any;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.toastNotificaiton.subscribe((res) => {
      console.log('toast Not', res);
      this.toast = res;
    });
  }

  handleClick() {
    this.appService.toastNotificaiton.next({
      type: 'error',
      title: 'Uh oh, something went wrong',
      subTitle: 'Please Enter Amount',
      icon: 'faExclamationTriangle',
    });
  }

  getIcon(): IconProp {
    let ic = faExclamationTriangle as IconProp;
    if (this.toast?.icon === 'faExclamationTriangle') {
      ic = faExclamationTriangle as IconProp;
    }

    if (this.toast?.icon === 'faHandHoldingHeart') {
      ic = faHandHoldingHeart as IconProp;
    }

    return ic;
  }
}
